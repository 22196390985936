import React, { useEffect, useState, type FC } from 'react';
import type { Carline } from '../../utils/filter';
import { FilterTag, Wrapper } from './filterTag';
import type { BodyType } from '../../../graphql/types';
import { useUniversalState } from '../../context/UniversalStateContext';
import type { TrackingManager } from '../../hooks/useTrackingManager';
import withTracking from '../../hoc/withTracking';

export type DisplayedBodyType = Omit<BodyType, '__typename'>;

// TODO: generate this out of the data we get from graphQL,
// this can only be done once the `bodyType` key has always a value
export const basicBodyTypes: string[] = [
  'sportback',
  'sportscar',
  'sport',
  'suv',
  'limo',
  'avant',
  'aaq',
  'coupe',
  'cabrio',
  'allstreet',
  'spyder',
  'roadster',
  'compact',
  'citycarver',
  'allroad quatro',
];

interface FilterBodyTypeTagsProps {
  readonly flattenedCarlines: Carline[];
  readonly filteredCarlines: Carline[] | undefined;
  readonly selectedBodyTypes: string[];
  readonly createOnClickHandler: (id: string) => () => void;
  readonly cutItemsAfter?: number;
  readonly trackingManager: TrackingManager;
}

export const FilterBodyTypeTags: FC<FilterBodyTypeTagsProps> = ({
  flattenedCarlines,
  filteredCarlines,
  selectedBodyTypes,
  createOnClickHandler,
  cutItemsAfter,
  trackingManager,
}) => {
  if (!flattenedCarlines) {
    return undefined;
  }

  const { translations } = useUniversalState();

  const availableBodyTypes = basicBodyTypes.filter((bodyType) =>
    flattenedCarlines?.some((carline) => carline.bodyTypes?.includes(bodyType)),
  );

  const [isShortened, setIsShortened] = useState<boolean>(
    cutItemsAfter ? availableBodyTypes.length > cutItemsAfter : false,
  );

  useEffect(() => {
    setIsShortened(cutItemsAfter ? availableBodyTypes.length > cutItemsAfter : false);
  }, [availableBodyTypes.length, cutItemsAfter]);

  let tags = availableBodyTypes.map((id) => {
    const isSelected = selectedBodyTypes.includes(id);
    const isDisabled = !isBodyTypeInCarlines(id, filteredCarlines) && !isSelected;
    return (
      <FilterTag
        key={id}
        text={translations[`bodyType.${id}`]}
        onClickHandler={createOnClickHandler(id)}
        selected={isSelected}
        disabled={isDisabled}
        dataTestId={`filterBodyType-${id?.replace(/\s+/g, '')}`}
      />
    );
  });
  if (isShortened) {
    tags = tags.slice(0, cutItemsAfter);
  }
  return (
    <Wrapper>
      {React.Children.map(tags, (tag) => (
        <div>{tag}</div>
      ))}
      {isShortened && (
        <div>
          <FilterTag
            key="expand"
            text=""
            onClickHandler={() => {
              setIsShortened(false);
              if (trackingManager) {
                trackingManager.trackShowMoreFilterClick('bodyTypes');
              }
            }}
            icon="more"
            dataTestId="expandBodyTypeFilter"
          />
        </div>
      )}
    </Wrapper>
  );
};

const isBodyTypeInCarlines = (bodyTypeId: string, filteredCarlines: Carline[] | undefined) => {
  let isInCarlines = true;
  // we need to check the `categories` of each carline and also the `bodyType`
  if (filteredCarlines && filteredCarlines.length > 0) {
    isInCarlines = filteredCarlines.some(
      (carline) => carline.bodyTypes && carline.bodyTypes?.includes(bodyTypeId),
    );
  }
  return isInCarlines;
};

export const TrackedFilterBodyTypeTags = withTracking(FilterBodyTypeTags);
