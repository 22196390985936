import { useQuery } from '@oneaudi/onegraph-client';
import type {
  CarlineStructureQuery,
  CarlineStructureQueryVariables,
} from '../../graphql/documents/carline-structure.generated';
import { CarlineStructureDocument } from '../../graphql/documents/carline-structure.generated';
import type { CarlineGroup } from '../../@types/mapped';

export const fetchCarlineGroups = (countryCode: string, language: string): CarlineGroup[] => {
  const { data } = useQuery<CarlineStructureQuery, CarlineStructureQueryVariables>(
    CarlineStructureDocument,
    {
      variables: {
        identifier: {
          brand: 'A',
          country: countryCode,
          language,
        },
        filterInput: {
          filterByProfileId: ['EXTERIOR-FRONT-3-4'],
        },
      },
      errorPolicy: 'ignore',
    },
  );
  const carlineGroups = data?.carlineStructure.carlineGroups
    .map((carlineGroup: CarlineGroup) => {
      const filteredCarlines = carlineGroup.carlines.filter((carline) => carline.links);
      return {
        ...carlineGroup,
        carlines: filteredCarlines,
      };
    })
    .filter((carlinesGroup: CarlineGroup) => carlinesGroup.carlines.length !== 0);
  return carlineGroups || [];
};
