import React, { useMemo } from 'react';
import styled, { keyframes } from 'styled-components';
import urlJoin from 'url-join';
import { getOptimizedImageSrc, getSizes, getSrcSet } from '../utils/image';
import { useFeatureAppEnv } from '../context/FeatureAppEnvContext';

import {
  IMG_SIZE_DESKTOP,
  IMG_SIZE_TABLET,
  IMG_SIZE_SMALL_TABLET,
  IMG_SIZE_IPHONE,
  IMG_SIZE_ZERO,
  CARLINE_JELLYBEAN_IMG_SIZE_DESKTOP,
  CARLINE_JELLYBEAN_IMG_SIZE_FULL,
  CARLINE_JELLYBEAN_IMG_SIZE_TABLET,
  CARLINE_JELLYBEAN_IMG_SIZE_SMALL_TABLET,
  CARLINE_JELLYBEAN_IMG_SIZE_IPHONE,
  CARLINE_JELLYBEAN_IMG_SIZE_ZERO,
  CARLINE_NGC_IMG_SIZE_DESKTOP,
  CARLINE_NGC_IMG_SIZE_FULL,
  CARLINE_NGC_IMG_SIZE_TABLET,
  CARLINE_NGC_IMG_SIZE_SMALL_TABLET,
  CARLINE_NGC_IMG_SIZE_IPHONE,
  CARLINE_NGC_IMG_SIZE_ZERO,
  IMAGE_MIME_TYPE,
  NGC_SCALE_FACTOR,
  JELLYBEAN_SCALE_FACTOR,
} from '../constants';

interface CarImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  index: number;
  alt: string;
  fallbackSrc?: string; // JellyBean image in case no ngc image is available
  ngcImageUrl?: string;
  backdropSrc?: string;
}

export const CardImage: React.FC<CarImageProps> = ({
  index,
  alt,
  fallbackSrc,
  ngcImageUrl,
  backdropSrc = `/assets/backdrops/backdrop`,
  ...props
}) => {
  const { baseUrl } = useFeatureAppEnv();

  const imageUrl = ngcImageUrl || fallbackSrc;

  const optimizedImageSrc = getOptimizedImageSrc(imageUrl, {
    extension: IMAGE_MIME_TYPE,
    width: CARLINE_NGC_IMG_SIZE_DESKTOP,
  });

  const imageSizes = useMemo(
    () =>
      ngcImageUrl
        ? [
            CARLINE_NGC_IMG_SIZE_FULL,
            CARLINE_NGC_IMG_SIZE_FULL * 2,
            CARLINE_NGC_IMG_SIZE_DESKTOP,
            CARLINE_NGC_IMG_SIZE_DESKTOP * 2,
            CARLINE_NGC_IMG_SIZE_TABLET,
            CARLINE_NGC_IMG_SIZE_TABLET * 2,
            CARLINE_NGC_IMG_SIZE_SMALL_TABLET,
            CARLINE_NGC_IMG_SIZE_SMALL_TABLET * 2,
            CARLINE_NGC_IMG_SIZE_IPHONE,
            CARLINE_NGC_IMG_SIZE_IPHONE * 2,
            CARLINE_NGC_IMG_SIZE_ZERO,
            CARLINE_NGC_IMG_SIZE_ZERO * 2,
          ]
        : [
            CARLINE_JELLYBEAN_IMG_SIZE_FULL,
            CARLINE_JELLYBEAN_IMG_SIZE_FULL * 2,
            CARLINE_JELLYBEAN_IMG_SIZE_DESKTOP,
            CARLINE_JELLYBEAN_IMG_SIZE_DESKTOP * 2,
            CARLINE_JELLYBEAN_IMG_SIZE_TABLET,
            CARLINE_JELLYBEAN_IMG_SIZE_TABLET * 2,
            CARLINE_JELLYBEAN_IMG_SIZE_SMALL_TABLET,
            CARLINE_JELLYBEAN_IMG_SIZE_SMALL_TABLET * 2,
            CARLINE_JELLYBEAN_IMG_SIZE_IPHONE,
            CARLINE_JELLYBEAN_IMG_SIZE_IPHONE * 2,
            CARLINE_JELLYBEAN_IMG_SIZE_ZERO,
            CARLINE_JELLYBEAN_IMG_SIZE_ZERO * 2,
          ],
    [ngcImageUrl],
  );
  const isNgcImage = !!ngcImageUrl;

  const selectedSizes = {
    full: isNgcImage ? CARLINE_NGC_IMG_SIZE_FULL : CARLINE_JELLYBEAN_IMG_SIZE_FULL,
    desktop: isNgcImage ? CARLINE_NGC_IMG_SIZE_DESKTOP : CARLINE_JELLYBEAN_IMG_SIZE_DESKTOP,
    tablet: isNgcImage ? CARLINE_NGC_IMG_SIZE_TABLET : CARLINE_JELLYBEAN_IMG_SIZE_TABLET,
    smallTablet: isNgcImage
      ? CARLINE_NGC_IMG_SIZE_SMALL_TABLET
      : CARLINE_JELLYBEAN_IMG_SIZE_SMALL_TABLET,
    iphone: isNgcImage ? CARLINE_NGC_IMG_SIZE_IPHONE : CARLINE_JELLYBEAN_IMG_SIZE_IPHONE,
    zero: isNgcImage ? CARLINE_NGC_IMG_SIZE_ZERO : CARLINE_JELLYBEAN_IMG_SIZE_ZERO,
  };

  return (
    <>
      {!ngcImageUrl && backdropSrc && (
        <BackdropImage
          src={urlJoin(baseUrl, `${backdropSrc}-${IMG_SIZE_IPHONE}.webp`)}
          alt={alt}
          loading={index === 0 ? 'eager' : 'lazy'}
          srcSet={getSrcSet(
            [
              IMG_SIZE_DESKTOP,
              IMG_SIZE_DESKTOP * 2,
              IMG_SIZE_TABLET,
              IMG_SIZE_TABLET * 2,
              IMG_SIZE_SMALL_TABLET,
              IMG_SIZE_SMALL_TABLET * 2,
              IMG_SIZE_IPHONE,
              IMG_SIZE_IPHONE * 2,
              IMG_SIZE_ZERO,
              IMG_SIZE_ZERO * 2,
            ].map((width) => ({
              src: urlJoin(baseUrl, `${backdropSrc}-${width}.webp`),
              width: `${width}w`,
            })),
          )}
          sizes={getSizes([
            { min: 'sxxl', width: `${IMG_SIZE_ZERO}px` },
            { min: 'm', width: `${IMG_SIZE_DESKTOP}px` },
            { min: 's', width: `${IMG_SIZE_TABLET}px` },
            { min: 'xs', width: `${IMG_SIZE_SMALL_TABLET}px` },
            { min: 'xxs', width: `${IMG_SIZE_IPHONE}px` },
            { width: `${IMG_SIZE_ZERO}px` },
          ])}
          width="1422"
          height="1067"
        />
      )}
      {optimizedImageSrc && (
        <Image
          src={optimizedImageSrc}
          alt={alt}
          loading={index === 0 ? 'eager' : 'lazy'}
          srcSet={getSrcSet(
            imageSizes.map((width) => ({
              src: getOptimizedImageSrc(optimizedImageSrc, { width }),
              width: `${width}w`,
            })),
          )}
          sizes={getSizes([
            { min: 'sxxl', width: `${selectedSizes.full}px` },
            { min: 'm', width: `${selectedSizes.desktop}px` },
            { min: 's', width: `${selectedSizes.tablet}px` },
            { min: 'xs', width: `${selectedSizes.smallTablet}px` },
            { min: 'xxs', width: `${selectedSizes.iphone}px` },
            { width: `${selectedSizes.zero}px` },
          ])}
          width={imageUrl ? '1422' : '1400'}
          height={imageUrl ? '1067' : '601'}
          hasNewImageFormat={!!ngcImageUrl}
          {...props}
        />
      )}
    </>
  );
};

const BackdropImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const getTransform = (hasNewImageFormat?: boolean) =>
  hasNewImageFormat
    ? `scale(${NGC_SCALE_FACTOR}) translateY(-11px)`
    : `scale(${JELLYBEAN_SCALE_FACTOR})`;

const Image = styled.img<{ hasNewImageFormat?: boolean }>`
  transform: ${(props) => getTransform(props.hasNewImageFormat)};
  width: 100%;
  object-fit: ${(props) => (props.hasNewImageFormat === true ? 'cover' : 'contain')};
  height: 100%;
  animation: ${fadeInAnimation} 1000ms;
`;
