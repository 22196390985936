import { Text } from '@audi/audi-ui-react';
import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { FootnoteReferenceV2 } from '@oneaudi/feature-app-utils';
import type { CarlineConsumptionAndEmissionSummary } from '../../graphql/documents/carline-structure.generated';
import { useFeatureAppEnv } from '../context/FeatureAppEnvContext';
import ConsumptionAndEmission from './ConsumptionAndEmission';
import Actions from './Actions';
import type { CarlineAvailableStock, FuelType } from '../../graphql/types';
import { CardImage } from './CardImage';
import { useContent } from '../context/ContentContext';
import { useI18n } from '../i18n';
import { useTrackingManager } from '../hooks/useTrackingManager';
import { Badges } from './Badges';

export interface CardProps {
  readonly index: number;
  readonly title: string;
  readonly carlineId: string;
  readonly transparentImageUrl?: string;
  readonly ngcImageUrl?: string;
  readonly price?: number;
  readonly discoverLink?: string;
  readonly configureLink?: string;
  readonly consumptionAndEmission?: CarlineConsumptionAndEmissionSummary;
  readonly availableStock?: CarlineAvailableStock;
  readonly fuelTypes: FuelType[];
  readonly group: string;
  readonly markAsNew: boolean;
}

export const Card: FC<CardProps> = ({
  index,
  title,
  carlineId,
  transparentImageUrl,
  ngcImageUrl,
  price,
  discoverLink,
  configureLink,
  consumptionAndEmission,
  availableStock,
  fuelTypes,
  group,
  markAsNew,
}) => {
  const { 'audi-number-formatter-service': numberFormatterService } =
    useFeatureAppEnv().featureServices;
  const {
    priceFootnotes,
    priceDisplayOption: { showBasePrice, showVat },
    caeDisplayOption: { showCae },
  } = useContent();

  const { clickCardImage } = useTrackingManager();

  return (
    <CardWrapper data-carlineid={carlineId} data-testid={`carCard-${carlineId}`}>
      <ImageWrapper backDropNumber="1" onClick={() => clickCardImage(group, title)}>
        <Badges fuelTypes={fuelTypes} markAsNew={markAsNew} />
        <CardImage
          index={index}
          alt={title}
          fallbackSrc={transparentImageUrl}
          ngcImageUrl={ngcImageUrl}
        />
      </ImageWrapper>
      <ContentWrapper>
        <Title variant="order4">{title}</Title>
        {price && showBasePrice && (
          <PriceWrapper>
            <Price variant="copy2" data-testid="price">
              {useI18n('priceFrom', { value: numberFormatterService.formatCurrency(price) })}
              {priceFootnotes?.map((footnote: string, i: number) => (
                <React.Fragment key={footnote}>
                  {i > 0 && <sup>{', '}</sup>}
                  <FootnoteReferenceV2 footnoteId={footnote} data-testid={`price-footnote_${i}`} />
                </React.Fragment>
              ))}
            </Price>
            {showVat && (
              <Text variant="copy2" as="span">
                {useI18n('vat')}
              </Text>
            )}
          </PriceWrapper>
        )}
        <Actions
          id={carlineId}
          title={title}
          discoverLink={discoverLink}
          configureLink={configureLink}
          availableStock={availableStock}
          group={group}
        />
        {consumptionAndEmission && showCae && (
          <DisclaimerWrapper>
            <ConsumptionAndEmission consumptionData={consumptionAndEmission} carlineName={title} />
          </DisclaimerWrapper>
        )}
      </ContentWrapper>
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  overflow: hidden;
  height: calc(100% - var(${(props) => props.theme.responsive.spacing.l}));
  background-color: var(${(props) => props.theme.colors.base.brand.white});
  display: flex;
  flex-direction: column;

  &:last-child {
    padding-bottom: var(${(props) => props.theme.responsive.spacing.l});
  }
`;

const ImageWrapper = styled.div<{ backDropNumber: string }>`
  position: relative;
  aspect-ratio: 4 / 3;
  font-size: 0;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: var(${(props) => props.theme.responsive.spacing.l})
    var(${(props) => props.theme.responsive.spacing.l}) 0;
`;

const Title = styled(Text)`
  text-wrap: pretty;
`;

const PriceWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: baseline;
  gap: 8px;
  margin-top: var(${(props) => props.theme.responsive.spacing.xxs});
`;

const Price = styled(Text)`
  white-space: nowrap;
`;

const DisclaimerWrapper = styled.div`
  position: relative;
  padding-top: var(${(props) => props.theme.responsive.spacing.l});
  background-color: var(${(props) => props.theme.colors.base.brand.white});
  margin-top: auto;
`;
